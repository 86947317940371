@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");


/* @import '../scss/_themes-vars.module.scss'; */
@import '~react-perfect-scrollbar/dist/css/styles.css';

html,
body {
    width: 100%;
    min-height: 100%;
    font-weight: 400;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
/* a:hover {
    color: #333;
    text-decoration: none;
} */

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: #000000;
}

input,
select {
    font-family: 'Lato', sans-serif;
}

p {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4em;
    text-align: left;
    color: #948fb5;
}

img {
    max-width: 100%;
}

.header-wrap {
    position: relative;
    top: 0;
    background: #fff;
    z-index: 9;
    border-bottom: 1px solid #0000FF;
    text-align: center;
}

.header-block {
    padding: 12px 0;
    text-align: center;
}

.header-block img {
    max-height: 46px;
}

.lg-title {
    font-style: normal;
    font-weight: 900;
    font-size: 1.4em;
    line-height: 1.4em;
    text-align: center;
    color: #333333;
}

.sm-title {
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.3em;
    text-align: center;
    color: #948fb5;
}

.custom-form .form-group {
    margin-bottom: 19px;
}

.custom-form label {
    font-size: 0.9em;
    margin-bottom: 4px;
    font-weight: 700;
    color: #948FB5;
}

.custom-form label.black-text {
    font-size: 1em;
}

.custom-form .form-control {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    padding: 14px 20px;
    border: none;
}

.custom-form .form-control::-ms-input-placeholder {
    opacity: 0.5;
}

.custom-form .form-control::placeholder {
    opacity: 0.5;
}

.custom-form select.form-control {
    background: #f6f5fd url(../img/down-arrow.svg) no-repeat calc(100% - 15px) center;
    background-size: 13px;
}

.custom-form textarea.form-control {
    resize: none;
    height: 105px;
}

.custom-form .form-control:focus {
    outline: none;
    box-shadow: none;
}

.theme-btn {
    background: #ff6624;
    border: solid 2px #ff6624;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    line-height: 17px;
    padding: 12px 20px;
    color: #fff;
    transition: all 0.5s ease-in-out 0s;
}

.save-btn {
    background: #ff6624;
    border: solid 1px #ff6624;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.8em;
    line-height: 10px;
    padding: 10px 25px;
    color: #fff;
    transition: all 0.5s ease-in-out 0s;
}

.negative-margin {
    margin-top: -20px;
}

.theme-btn:hover {
    background: #fff;
    color: #ff6624;
    border: solid 2px #ff6624;
}

.save-btn:hover {
    background: #fff;
    color: #ff6624;
    border: solid 1px #ff6624;
}

.gray-btn {
    background: transparent;
    border: solid 2px #948fb5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1em;
    line-height: 17px;
    padding: 15px 20px;
    color: #948fb5;
    transition: all 0.5s ease-in-out 0s;
}

.gray-btn:hover {
    background: #948fb5;
    color: #333;
}

.mxw-600 {
    max-width: 600px;
    margin: 0 auto;
}

.mxw-750 {
    max-width: 750px;
    margin: 0 auto;
}

.mxw-400 {
    max-width: 400px;
    margin: 0 auto;
}

.form-card .card-body {
    padding: 30px 45px;
}

.footer-wrap {
    background: #ffffff;
    border-top: 1px solid #eee9fc;
    padding: 15px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #948fb5;
    text-align: center;
}

.orange-text {
    color: #ff6624 !important;
}

.myPinCode input {
    width: 52px !important;
    height: 52px !important;
    text-align: center;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    padding: 14px 20px;
    border: none;
    box-shadow: none;
    outline: none;
}

.myPinCode input:focus {
    border: none;
    box-shadow: none;
}

.mid-wrap {
    min-height: calc(100vh - 161px);
    position: relative;
    /* z-index: 0; */
}

.mid-card-block {
    width: 100%;
    padding: 0 0 0;
}

.form-card {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    border: none;
    background: #FFFFFF;
    border-radius: 4px;
}

.blue-text {
    color: #0000ff !important;
}

.border-b {
    border-bottom: 1px solid #eee9fc;
}

.border-l {
    border-left: 1px solid #eee9fc;
}

.ml-10 {
    margin-left: 10px !important;
}

.md-title {
    font-weight: 900;
    font-size: 1.1em;
    color: #333333;
}

.font-0-8em {
    font-size: 0.8em !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.circle-box {
    width: 60px;
    height: 60px;
    background: #e0ede7;
    border-radius: 50%;
    position: relative;
    min-width: 60px;
    overflow: hidden;
    border: solid 1px #f4f5f4;
}

.circle-box img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}

.ml-15 {
    margin-left: 15px !important;
}

.black-text {
    color: #333333 !important;
}

.gray-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 15px;
}

.gray-box .icon {
    min-height: 32px;
    margin-bottom: 1px;
}

.gray-box .icon img {
    max-height: 20px;
}

.dtl-info .gray-box {
    padding: 7px 15px;
}

.dtl-info .sm-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #333333;
}

.green-text {
    color: #0d942b !important;
}

.call-dtl-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 10px 5px 10px 15px;
}

.recording-icon {
    background: url(../img/recording.svg) no-repeat center center;
    background-size: 100%;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: -3px;
    margin-right: 3px;
}

.theme-btn:hover .recording-icon {
    background: url(../img/orange-recording.svg) no-repeat center center;
    background-size: 100%;
}

.pitch-recording-btn {
    text-transform: inherit;
    font-size: 0.7em;
    font-weight: 700;
    padding: 10px 24px;
}

.pl-6 {
    padding-left: 6px !important;
}

.red-btn {
    background: rgba(211, 47, 47, 0.1);
    border: 1px solid rgba(211, 47, 47, 0.3);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #d32f2f;
    height: 46px;
    line-height: 44px;
}

.red-btn.is-not-interested {
    background: #d32f2f;
    border-color: #d32f2f;
    color: #fff;
}

.green-btn {
    background: rgba(13, 148, 43, 0.1);
    border: 1px solid rgba(13, 148, 43, 0.3);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #0d942b;
    height: 46px;
    line-height: 44px;
}

.green-btn.is-interested {
    background: #0d942b;
    border-color: #0d942b;
    color: #fff;
}

.d-green-btn {
    background: #0d942b;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    height: 46px;
    border: none;
    line-height: 44px;
}

.d-green-btn:disabled {
    opacity: 0.5;
    color: rgb(255 255 255 / 50%);
}

.pitch-call-action .d-green-btn {
    /* left: 50%; */
    transform: translate(25%, 0);
    position: absolute;
    width: 50%;
}

.d-green-btn img {
    vertical-align: -2px;
}

.d-green-btn span {
    padding: 0 15px;
}

.mnw-165 {
    min-width: 200px !important;
}

.lh-1-5em {
    line-height: 1.5em !important;
}

.font-1-2em {
    font-size: 1.1em !important;
}

.menu ul {
    padding: 0;
    margin: 0;
}

.menu ul li a {
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 700;
}

.menu ul li:last-child a {
    padding-right: 0;
}

.menu ul li a.active {
    color: #ff6624;
}

.vendor-info img {
    max-width: 100px;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-3 {
    padding-right: 3rem !important;
}

.font-1em {
    font-size: 1em !important;
}

.fw-600 {
    font-weight: 400 !important;
}

.white-right-box {
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 40px 35px;
}

.desc {
    margin-top: 10px;
}

.desc p {
    font-weight: 400;
    color: #333;
    font-size: 1em;
}

.social-icons a {
    margin-right: 10px;
}

.video-thumb img {
    width: 100%;
    max-width: 340px;
}

.deal-dsc-block .card {
    border-color: #eee9fc;
}

.deal-dsc-block .card-body {
    padding: 25px;
}

.custom-table tr th {
    font-weight: 400;
    font-size: 1em;
    color: #333333;
    padding: 3px;
}

.custom-table tr td {
    font-size: 1em;
    font-weight: 400;
    color: #948fb5;
    padding: 4px 3px;
}

.realtime-logo {
    max-width: 58px;
    vertical-align: -6.5px;
    margin-left: 3px;
    border-radius: 2px;
}

.rating-count {
    font-weight: 700;
    font-size: 2.5em;
}

.rating-stars img {
    display: inline-block;
}

.plr-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.realtime-analysis-block .custom-table tr td {
    padding: 6px 3px;
}

.deal-flow-item {
    padding: 0 0 30px;
    position: relative;
    padding-left: 30px;
}

.deal-flow-item:last-child {
    margin-bottom: 0;
}

.deal-flow-item:before {
    content: '';
    position: absolute;
    left: 8px;
    top: 4px;
    border-left: 2px dashed #948fb5;
    bottom: 0;
}

.deal-flow-item:last-child:before {
    display: none;
}

.deal-flow-item.completed:after {
    background: #0d942b;
}

.deal-flow-item.inprogress:after {
    background: #ff6624;
}

/* .deal-flow-item:after {
    content: '';
    position: absolute;
    left: -8px;
    top: 2px;
    background: #948fb5;
    width: 14px;
    height: 14px;
    border-radius: 50%;
} */

.back-arrow img {
    max-width: 24px;
    display: block;
}

.pl-15 {
    padding-left: 15px !important;
}

.kyc-form-block {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
}

.kyc-form-block .form-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.custom-radio [type='radio']:checked,
.custom-radio [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.custom-radio [type='radio']:checked+label,
.custom-radio [type='radio']:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #333;
    font-size: 0.85em;
}

.custom-radio [type='radio']:checked+label:before,
.custom-radio [type='radio']:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
}

.custom-radio [type='radio']:checked+label:before {
    border: 1px solid #ff6624;
}

.custom-radio [type='radio']:checked+label:after,
.custom-radio [type='radio']:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff6624;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.custom-radio [type='radio']:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.custom-radio [type='radio']:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.radio-inline {
    display: inline-block;
}

.custom-checkbox .styled-checkbox {
    position: absolute;
    opacity: 0;
}

.custom-checkbox .styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    color: #333333;
    font-size: 0.85em;
}

.custom-checkbox .styled-checkbox+label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 2px;
    position: absolute;
    top: -1px;
    left: 0;
}

.custom-checkbox .styled-checkbox:checked+label:before {
    background: #f35429;
    border: 1px solid #f35429;
}

.custom-checkbox .styled-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

.xs-title {
    font-weight: 700;
    font-size: 0.7em;
    color: #948fb5;
}

.custom-upload {
    position: relative;
    overflow: hidden;
}

.custom-upload .custom-upload-btn {
    background: rgba(0, 0, 255, 0.1);
    border: 1px solid rgba(0, 0, 255, 0.2);
    border-radius: 8px;
    color: #2433ff;
    font-weight: 700;
    font-size: 1em;
    padding: 10px 15px;
    width: 100%;
}

.custom-upload input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.uploaded-imgs-block {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.uploaded-box {
    position: relative;
    margin-right: 10px;
}

.uploaded-box>img {
    width: 100%;
    border-radius: 4px;
}

.cross-icon {
    position: absolute;
    right: -11px;
    top: -13px;
    cursor: pointer;
}

.mr-15 {
    margin-right: 15px !important;
}

.custom-form .form-control.calendar {
    background: #f6f5fd url(../img/calendar.svg) no-repeat calc(100% - 15px) center;
    background-size: 20px;
    padding-right: 45px;
}

/* Date Picker */
.datepicker table tr td,
.datepicker table tr th {
    text-align: center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    border: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover {
    background-image: none;
    background-color: #ff6624 !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover {
    background-image: none;
    background-color: #FF6624 !important;
    color: #fff;
}

.mxw-360 {
    max-width: 360px;
    margin: 0 auto;
    line-height: 1.4em;
}

.success-menu {
    text-align: center;
}

.success-menu ul {
    display: inline-flex;
}

.success-menu ul li a {
    padding: 0 25px;
    display: block;
    color: #333333;
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    text-decoration-line: underline;
    line-height: 1em;
}

.success-menu ul li:last-child {
    border-left: solid 1px #d9d9d9;
}

.mxw-800 {
    max-width: 750px;
    margin: 0 auto;
}

.pd-50 {
    padding: 50px !important;
}

.doc-info-list {
    padding-left: 15px;
}

.doc-info-list li {
    padding: 1px 0;
    list-style-type: disc;
    font-size: 0.9em;
}

.kyc-status-tracking .deal-flow-item:after {
    top: 2px;
    transform: inherit;
}

.kyc-status-tracking .deal-flow-item.disabled {
    opacity: 0.5;
}

.kyc-status-tracking .deal-flow-item.pending:after {
    background: #d32f2f;
}

.transparent-orange-btn {
    background: #ffffff;
    border: 1px solid #ff6624;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.7em;
    text-align: center;
    text-transform: uppercase;
    color: #ff6624;
    padding: 8px 15px 7px 15px;
    transition: all 0.5s ease-in-out 0s;
}

.transparent-orange-btn:hover {
    background: #ff6624;
    border: 1px solid #ff6624;
    color: #fff;
}

.transparent-btn {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.7em;
    text-align: center;
    color: #333;
    padding: 2px 5px 2px 5px;
    transition: all 0.5s ease-in-out 0s;
}

.transparent-btn:hover {
    color: #333;
}

.lgray-btn {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 8px;
    color: #948fb5;
    font-weight: 700;
    font-size: 1em;
    padding: 10px 15px;
    width: 100%;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out 0s;
}

.lgray-btn:hover {
    color: #fff;
    background: #333;
    border: 1px solid #333;
}

.msg-icon {
    background: url(../img/message-icon.svg) no-repeat center center;
    background-size: 100%;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 2px;
}

.lgray-btn:hover .msg-icon {
    background: url(../img/message-icon-hover.svg) no-repeat center center;
}

.custom-form .custom-radio.radio-inline,
.custom-form .custom-checkbox.d-inline-block {
    width: 33.33%;
    margin-bottom: 11px;
}

.custom-dropdown .dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
}

.custom-dropdown .dropdown-toggle::after {
    display: none;
}

.custom-dropdown .dropdown-menu {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(186, 184, 184, 0.6);
    border-radius: 4px;
    border: none;
    padding: 0;
}

.custom-dropdown .dropdown-menu li+li {
    border-top: solid 1px #eee9fc;
}

.custom-dropdown .dropdown-menu .dropdown-item {
    font-weight: 700;
    font-size: 1em;
    color: #333333;
    padding: 11px 20px;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item:focus {
    background-color: #ff6624;
    color: #fff;
}

.custom-dropdown .dropdown-menu li:first-child .dropdown-item {
    border-radius: 4px 4px 0 0;
}

.custom-dropdown .dropdown-menu li:last-child .dropdown-item {
    border-radius: 0 0 4px 4px;
}

.share-modal .modal-dialog {
    max-width: 374px;
}

.social-share {
    text-align: center;
}

.social-share a {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: solid 1px #333;
    position: relative;
}

.social-share a span {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    transition: all 0.5s ease-in-out 0s;
}

.social-share .fb-box {
    border-color: #3d6ad6;
}

.social-share .fb-box span {
    background: url(../img/facebook.png) no-repeat center center;
    background-size: 100%;
}

.social-share .fb-box:hover {
    background: rgb(61 106 214 / 10%);
}

/* .social-share .fb-box:hover span {
    background: url(../img/facebook.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .tw-box {
    border-color: #03a9f4;
}

.social-share .tw-box span {
    background: url(../img/twitter.png) no-repeat center center;
    background-size: 100%;
}

.social-share .tw-box:hover {
    background: rgb(3 169 244 / 10%);
}

/* .social-share .tw-box:hover span {
    background: url(../img/twitter.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .ins-box {
    border-color: #ff7543;
}

.social-share .ins-box span {
    background: url(../img/instagram.png) no-repeat center center;
    background-size: 100%;
}

.social-share .ins-box:hover {
    background: rgb(255 117 67 / 10%);
}

/* .social-share .ins-box:hover span {
    background: url(../img/instagram.png) no-repeat center center;
    background-size: 100%;
} */
.social-share .wh-box {
    border-color: #29a71a;
}

.social-share .wh-box span {
    background: url(../img/whatsapp.png) no-repeat center center;
    background-size: 100%;
}

.social-share .wh-box:hover {
    background: rgb(41 167 26 /10%);
}

/* .social-share .wh-box:hover span {
    background: url(../img/whatsapp.png) no-repeat center center;
    background-size: 100%;
} */
.custom-modal .modal-content {
    border: none;
    border-radius: 8px;
}

.custom-modal .modal-body {
    padding: 35px;
}

.document-list li {
    padding: 20px 0;
}

.document-list li+li {
    border-top: 1px solid #eee9fc;
}

.view-icon img {
    max-width: 20px;
    display: block;
}

.ml-auto {
    margin-left: auto !important;
}

.deal-heading {
    width: calc(100% - 300px);
    text-align: center;
}

.gray-box .icon-md {
    min-height: 80px;
}

.gray-box .icon-md img {
    max-width: 70px;
    max-height: 70px;
}

.common-content p {
    text-align: center;
}

.about-info-list .gray-box {
    padding: 35px 20px;
}

.about-info-list .md-title {
    min-height: 60px;
}

.accordion-item {
    border: none;
    margin-bottom: 15px;
}

.accordion-button {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    background: #f6f5fd;
    border-radius: 4px;
}

.accordion-button:not(.collapsed) {
    background: #f6f5fd;
    color: #333333;
    box-shadow: none;
    border-radius: 4px 4px 0 0;
}

.accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.accordion-collapse {
    border: 1px solid #eee9fc;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.accordion-collapse p {
    font-size: 1em;
    list-style-type: disc;
    padding: 6px 0;
    color: #948fb5;
}

.accordion-collapse ul {
    padding-left: 30px;
}

.accordion-collapse ul li {
    font-size: 1em;
    list-style-type: disc;
    padding: 6px 0;
    color: #948fb5;
}

.accordion-button::after {
    background: url(../img/faq-down-arrow.svg) no-repeat center center;
    background-size: 100%;
    width: 14px;
    height: 14px;
}

.accordion-button:not(.collapsed)::after {
    background: url(../img/faq-down-arrow.svg) no-repeat center center;
    background-size: 100%;
}

.pitch-call-action .d-green-btn.pitch_no {
    transform: translate(0, 0);
}

.pitch-call-action .d-green-btn.pitch_yes {
    transform: translate(100%, 0);
}

.toggle_chk {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}

.lg-title span {
    position: relative;
}

.lg-title span:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translate(-50%, 0);
    width: 60px;
    height: 3px;
    background: #0000ff;
    border-radius: 3px;
}

.modal-heading-flex .md-title {
    width: calc(100% - 28px);
    text-align: center;
}

.document-modal .modal-dialog {
    max-width: 550px;
}

.forms-header {
    position: relative;
    top: 0;
    z-index: 9;
    background: #fff;
}

.startup-profile-header {
    position: sticky;
    top: 71px;
    z-index: 1;
    background: #fff;
}

.common-modal.show {
    display: block !important;
    background: rgb(0 0 0 / 50%);
}

.deal-flow-circle {
    position: absolute;
    left: 2px;
    top: 3px;
    background: #0d942b;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

.modal-body {
    padding: 25px;
}

.loading-box {
    min-height: calc(100vh - 126px);
    width: 100%;
    text-align: center;
}

.loading-text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.instructions-table {
    table-layout: fixed;
    font-size: 16px;
}

.instructions-table tr {
    height: 50px;
    vertical-align: middle;
}

.white-btn {
    background: #FFFFFF;
    border: 1px solid #EEE9FC;
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
    color: #948FB5;
    display: inline-block;
    padding: 12px 15px;
}

.w-360 {
    width: 360px;
}

.mt-30 {
    margin-top: 30px !important;
}

.process-step {
    background: #EEE9FC;
    border-radius: 50px;
    width: 38px;
    height: 5px;
    display: inline-block;
    margin: 0 5px;
}

.process-step.selected {
    background: #948FB5;
}

.process-step.active {
    background: #10B53E;
}

.required {
    position: relative;
    padding-right: 8px;
}

.required:after {
    content: "*";
    position: absolute;
    right: 0;
    color: #DF3D0F;
}

.forms-header.border-b {
    border-bottom: none !important;
}


.react-checkbox label {
    margin-bottom: 0;
    display: block;
}

.react-checkbox input {
    position: absolute;
    opacity: 0;
}

.react-checkbox input+span {
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    color: #333333;
    display: inline-block;
}

.react-checkbox input+span:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 22px;
    height: 22px;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 2px;
    position: absolute;
    top: -1px;
    left: 0;
}

.react-checkbox input:checked+span:before {
    background: #f35429;
    border: 1px solid #f35429;
}

.react-checkbox input:checked+span:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
    transform: rotate(45deg);
}

.rr-col-2 .react-checkbox {
    display: inline-block;
    width: 33.33%;
    margin-bottom: 8px;
    margin-top: 4px;
    margin-right: 15px;
}


.react-radio [type='radio']:checked,
.react-radio [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.react-radio label {
    color: #333;
    font-weight: 700;
}

.react-radio [type='radio']:checked+span,
.react-radio [type='radio']:not(:checked)+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

.react-radio [type='radio']:checked+span:before,
.react-radio [type='radio']:not(:checked)+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #f6f5fd;
    border: 1px solid #eee9fc;
}

.react-radio [type='radio']:checked+span:before {
    border: 1px solid #ff6624;
}

.react-radio [type='radio']:checked+span:after,
.react-radio [type='radio']:not(:checked)+span:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff6624;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-radio [type='radio']:not(:checked)+span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.react-radio [type='radio']:checked+span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.rr-col-2 .react-radio {
    display: inline-block;
    width: 33.33%;
    margin-bottom: 8px;
    margin-top: 4px;
}

.mt-10 {
    margin-top: 10px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.mxw-520 {
    max-width: 520px;
    margin: 0 auto;
}

.form-card .theme-btn {
    padding: 15px 20px;
}

.join-box {
    font-family: 'Lato', sans-serif;
    box-shadow: none;
    padding: 0;
}

.join-box .theme-btn {
    padding: 7px 15px;
    font-size: 12px;
}

.btn-340 {
    max-width: 340px;
}

.mt-25 {
    margin-top: 25px;
}

.myPinCode {
    max-width: 280px;
    margin: 0 auto;
}

.resend-btn button {
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #948FB5;
}

.resend-btn {
    text-align: center;
    display: block !important;
}

.kyc-form-block {
    margin-bottom: 3rem;
}

.choose-option {
    padding-left: 10%;
}

.choose-option .radio-inline {
    display: block !important;
    padding: 12px 0;
}

.mxw-460 {
    max-width: 460px;
    margin: 0 auto;
}

.heading-formobile {
    display: none;
}

.kyc-form-block.pt-0 {
    padding-top: 1.5rem !important;
}

.choose-option .react-radio [type='radio']:checked+span,
.choose-option .react-radio [type='radio']:not(:checked)+span {
    font-weight: 900;
    text-transform: uppercase;
    color: #948FB5;
    letter-spacing: 1.62px;
    padding-left: 43px;
}

.fw-700 {
    font-weight: 700 !important;
}

.blank-link {
    color: #948FB5;
}

.font-16 {
    font-size: 16px !important;
}

.radio-col-2 .react-radio.radio-inline,
.checkbox-col-2 .react-checkbox {
    width: 35%;
    margin: 4px 0;
    display: inline-block;
}

.rradio-mb .react-checkbox {
    margin: 5px 0 7px;
}

.error-box {
    background: #fff;
    padding: 22px;
    max-width: 370px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    text-align: center;
    font-weight: 400;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background: #ff6624;
    border: solid 2px #ff6624;
    color: #fff;
    opacity: 0.5;
}

.checkbox-col-2 .singleline .react-checkbox {
    width: 100%;
}

.forms-header .lg-title {
    text-transform: capitalize;
}

.change-btn {
    border: none;
    background: transparent;
    color: #ff6624;
    padding: 0;
    font-size: 13px;
    padding-left: 5px;
}

.pdficon {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    align-items: center;

}

.pdficon img {
    max-width: 35px;
}

.pdfname {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: calc(100% - 45px);
}

.typepdf .uploaded-box {
    width: 93% !important;
}

.typepdf .uploaded-box .cross-icon {
    top: 0;
}

.home-card-block {
    max-width: 100%;
}

.home-card-block .card-body {
    padding: 60px 80px;
}

.home-card-block .w-60 {
    width: 60%;
}

.home-card-block .w-40 {
    width: 40%;
}

.hw-download-title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 1.2em;
    color: #FF6624;
    margin-bottom: 2.5rem !important;
}

.hc-logo img {
    max-width: 260px;
}

.home-card-block p {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.4em;
    color: #948FB5;
    margin: 1.5rem 0 2rem;
}

.home-store-link img {
    max-width: 200px;
}

.home-app-screen img {
    max-width: 220px;
}

.mxw-650 {
    max-width: 650px;
}

.home-tab-list {
    padding: 22px 0 0;
    margin: 0 auto;
}

.home-tab-list li+li {
    margin-top: 28px;
}

.home-tab-list li a {
    background: #FFFFFF;
    border: 2.53471px solid #EEE9FC;
    box-shadow: 0px 5.06942px 25.3471px rgba(221, 209, 255, 0.6);
    border-radius: 25.3471px;
    border-radius: 15px;
    padding: 18px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    /* font-family: 'Lato', sans-serif; */
}

.hm-icon {
    width: 30px;
    margin-right: 14px;
}

.hml-title {
    width: calc(100% - 94px);
}

.main-home-card .form-card {
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(221, 209, 255, 0.6);
}

.resources-slider.owl-carousel .owl-item img {
    max-width: 100% !important;
}

.badge {
    background: #EEE9FC;
    width: 26px;
    height: 26px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #948FB5;
    border-radius: 50%;
    padding: 0;
    margin-right: 15px;
    min-width: 26px;
}

.badge.active {
    background: #FF6624;
    color: #fff;
}

.idil-btn {
    padding-left: 35px;
    padding-right: 35px;
}

.custom-card {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.common-left {
    min-width: 330px;
}

.common-left .custom-card {
    min-height: calc(100vh - 85px);
    position: sticky !important;
    top: 80px;
    padding-bottom: 69px;
}

.common-right {
    width: calc(100% - 330px);
    padding-left: 15px;
}

.common-right>.custom-card {
    min-height: calc(100vh - 85px);
}

.left-menu {
    overflow-x: auto;
    max-height: calc(100vh - 153px);
}

.left-menu li a {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.3em;
    color: #948FB5;
    padding: 20px 22px;
    display: block;
}

.left-menu li a.active {
    background: #F3F4F9;
    color: #FF6624;
    font-weight: 700;
}

.lmenu-icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: -9px;
    margin-right: 10px;
}

.svg-icon {
    vertical-align: 2px;
    text-align: center;
}

.svg-icon .bi {
    font-size: 24px;
    vertical-align: middle;
    color: #948FB5;
}

.svg-icon svg {
    width: 26px;
    height: 28px;
    vertical-align: middle;
}

.home-icon {
    background: url(../img/home-icon.svg) no-repeat center center;
    background-size: 100%;
}

.left-menu a.active .home-icon,
.ud-menu li a.active .home-icon {
    background: url(../img/home-icon-hover.svg) no-repeat center center;
    background-size: 100%;
}

.deals-icon {
    background: url(../img/deals-icon.svg) no-repeat center center;
    background-size: 100%;
}

.left-menu a.active .deals-icon,
.ud-menu li a.active .deals-icon {
    background: url(../img/deals-icon-hover.svg) no-repeat center center;
    background-size: 100%;
}

.checklist-icon {
    background: url(../img/checklist.svg) no-repeat center center;
    background-size: 100%;
}

.left-menu a.active .checklist-icon,
.ud-menu li a.active .checklist-icon {
    background: url(../img/checklist-hover.svg) no-repeat center center;
    background-size: 100%;
}

.portfolio-icon {
    background: url(../img/portfolio-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .portfolio-icon,
.ud-menu li a.active .portfolio-icon {
    background: url(../img/portfolio-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.resource-icon {
    background: url(../img/resources-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .resource-icon,
.ud-menu li a.active .resource-icon {
    background: url(../img/resources-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.about-icon {
    background: url(../img/about-icon.svg) no-repeat center center;
    background-size: 26px 27px;
}

.left-menu a.active .about-icon,
.ud-menu li a.active .about-icon {
    background: url(../img/about-icon-hover.svg) no-repeat center center;
    background-size: 26px 27px;
}

.contact-icon {
    background: url(../img/contact-icon.svg) no-repeat center center;
    background-size: 22px 22px;
}

.left-menu a.active .contact-icon,
.ud-menu li a.active .contact-icon {
    background: url(../img/contact-icon-hover.svg) no-repeat center center;
    background-size: 22px 22px;
}

.logout-icon {
    background: url(../img/logout-icon.svg) no-repeat center center;
    background-size: 22px 22px;
}

.left-menu a.active .logout-icon,
.ud-menu li a.active .logout-icon {
    background: url(../img/logout-icon-hover.svg) no-repeat center center;
    background-size: 22px 22px;
}

.gray-btop-10 {
    border-top: solid 10px #EEE9FC;
}

.text-gray {
    color: #777 !important;
}

.font-10 {
    font-size: 10px !important;
}

.di-sub-label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14.4px;
    line-height: 17px;
    color: #948FB5;
}

.di-sub-value {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16.2px;
    line-height: 20px;
    color: #333333;
}

.detail-dtl-list .form-card+.form-card {
    margin-top: 15px;
}

.dl-heading {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14.4px;
    line-height: 17px;
    color: #948FB5;
}

.user-dropdown {
    border: none;
    background: transparent;
}

.ud-name {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #0000FF;
}

.ud-name {
    margin-left: 10px;
}

.ud-arrow {
    background: url(../img/gray-down-arrow.svg) no-repeat center center;
    background-size: 100%;
    width: 16px;
    height: 10px;
    margin-left: 7px;
    display: inline-block;
    margin-top: 3px;
}

.ud-block {
    position: relative;
}

.ud-menu {
    position: absolute;
    right: 0;
    top: 110%;
    background: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    padding: 0;
    border-radius: 5px;
    width: 180px;
    display: none;
}

.ud-block .ud-menu.show {
    display: block;
}

.ud-menu:before {
    content: "";
    position: absolute;
    right: 25px;
    top: -7px;
    border-left: solid 7px transparent;
    border-right: solid 7px transparent;
    border-bottom: solid 7px #fff;
}

.ud-menu li a {
    text-align: left !important;
    padding: 7px 15px;
    display: block;
}

.ud-for-mobile {
    display: none;
}

.ud-menu li a .lmenu-icon {
    margin-right: 4px;
}

.mobile-menuicon {
    border: none;
    background: transparent;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
    margin-left: 20px;
    display: none;
}

.mobile-menuicon img {
    max-width: 28px;
}


.demo-server-block {
    width: 100%;
    padding: 5px;
    background-color: #0000FF;
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.hm-arrow img {
    display: block;
}

.change-amount-btn,
.confirm-amount-btn {
    line-height: normal;
    font-size: 13px;
}

.info-amount {
    font-size: 14px;
    color: #FF6624;
}

.info-amount img {
    max-width: 20px;
    vertical-align: -5px;
    margin-right: 1px;
}

.balance-fund-box {
    border-radius: 20px;
    border: 2px solid var(--purple, #EEE9FC);
    background: var(--white, #FFF);
    padding: 10px 14px;
}

.wallet-icon {
    background: url(../img/removebg-preview.svg) no-repeat center center;
    background-size: 100%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.form-check-input:focus {
    box-shadow: none;
}

.not-interest-text {
    font-size: 14px;
    color: #D32F2F;
}

.cursor-pointer {
    cursor: pointer;
}

.unallocated-text {
    font-size: 14px;
    color: #FF6624;
    margin-top: 10px;
    display: inline-block;
    line-height: 22px;
}

.blueInfoIcon {
    background: url(../img/information-line.svg) no-repeat center center;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: 100%;
    vertical-align: -5px;
    margin-right: 1px;
}

.page-title {
    color: #FF6624;
    font-weight: 700;
    font-size: 1.3em;
}

.logout-menu {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.logout-menu a {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.5em;
    color: #948FB5;
    padding: 20px 22px;
    display: block;
}

.deal-info-block .dib-left-box {
    border-right: solid 1px #eee9fc;
}

.font-0-7em {
    font-size: 0.7em !important;
}

.dtl-info-box {
    width: 95px;
    margin-right: 10px;
}

.pitch-info-box {
    width: 120px;
    margin-right: 0;
}

.dtl-info .col-info-box {
    margin-right: 0.5rem !important;
    width: 120px;
}

.w-40 {
    width: 40% !important;
}

.my-profile-card li .btn-sm {
    font-size: 12px;
}

.my-profile-card li a {
    height: 68px;
}

.theme-btn.btn-sm {
    font-size: 0.8em;
    padding: 9px 15px;
}

.xs-title {
    font-size: 0.95em;
    color: #333333;
}

.favourite-list ul {
    padding: 0;
    margin: 0;
}

.favourite-list li {
    padding: 15px 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.statement-icon img {
    max-width: 130px;
}

.sm-btn {
    font-size: 14px;
    padding: 10px 15px;
}

.portfolio-card {
    background: #FFFFFF;
    border: 0px solid #EEE9FC;
    box-shadow: 0px 5.06942px 25.3471px rgba(221, 209, 255, 0.6);
    border-radius: 25.3471px;
    border-radius: 15px;
    padding: 18px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    border-left: solid 7px transparent;
}

.portfolio-card+.portfolio-card {
    margin-top: 15px;
}

.portfolio-card .circle-box {
    width: 50px;
    height: 50px;
    min-width: 50px;
}

.filter-selector .custom-form .form-control {
    padding: 8px 34px 8px 15px;
    font-size: 0.9rem;
}

.mxw-300 {
    max-width: 300px;
    margin: 0 auto;
}

.filter-btn {
    background: #f6f5fd;
    border: none;
    padding: 9px 11px;
    border-radius: 8px;
    margin-left: 10px;
}

.custom-tabs .nav-tabs {
    border-bottom: none;
}

.custom-tabs .nav-tabs li+li {
    margin-left: 4px;
}

.custom-tabs .nav-tabs .nav-link {
    background: #f6f5fd;
    border: solid 1px #fff;
    border-bottom: none;
    border-radius: 0;
    color: #333;
    margin-bottom: 0;
}

.custom-tabs .nav-tabs .nav-link.active {
    border: solid 1px #e1e1e1;
    border-bottom: none;
    bottom: -1px;
    position: relative;
}

.custom-nav-tabs {
    display: flex;
    padding: 0;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.custom-nav-tabs.nav-tabs .nav-link {
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    color: #9e9e9e;
    cursor: pointer;
    border-bottom: solid 2px transparent;
}

.custom-nav-tabs.nav-tabs .nav-link,
.custom-nav-tabs.nav-tabs .nav-link.active,
.custom-nav-tabs.nav-tabs .nav-item.show .nav-link {
    border: none;
    border-bottom: solid 2px transparent;
}

.custom-nav-tabs.nav-tabs .nav-link.active {
    color: #FF6624;
    margin-bottom: 0;
    border-bottom: solid 2px #FF6624;
}

.custom-tabs .tab-content {
    border: solid 1px #e1e1e1;
    padding: 15px 20px 25px;
}

.close-btn {
    padding: 12px 15px;
}

.cc-common-card .cr-heading {
    padding: 17px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pt-icon {
    max-width: 60px;
    margin: 0 auto 10px;
    min-height: 60px;
}

.portfolio-card-list .accordion-button {
    padding: 0;
    background: transparent;
}

.portfolio-card-list .accordion-button::after {
    display: none;
}

.portfolio-card-list .portfolio-card {
    padding: 0;
    border-radius: 15px !important;
}

.portfolio-card-list .portfolio-card .accordion-button {
    padding: 18px 20px;
}

.portfolio-card-list .accordion-collapse {
    border: none;
}

.portfolio-card-list .accordion-collapse.show {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.portfolio-card-list .cs-accordion .accordion-button {
    background: #f6f5fd;
    color: #333333;
    box-shadow: none;
    border-radius: 4px 4px 0 0 !important;
}

.portfolio-card-list .cs-accordion .accordion-collapse {
    border: 1px solid #eee9fc;
    border-top: none;
    border-radius: 0 0 4px 4px !important;
}

.font-14 {
    font-size: 14px !important;
}

.gray-text {
    color: #948FB5 !important;
}

.blue-box {
    padding: 30px;
    background-color: #FF6624;
    border-radius: 15px;
}

.extra-link-actions {
    position: absolute;
    right: 8px;
    top: 15px;
}

.extra-link-actions .ela-action {
    padding: 0 7px;
    display: inline-block;
    vertical-align: middle;
}

.extra-link-actions .ela-action .bi {
    font-size: 18px;
}

.extra-link-actions .custom-dropdown .btn {
    outline: none;
    padding: 5px 11px 5px 5px;
    border: none;
}

.extra-link-actions .custom-dropdown .btn:focus {
    outline: none;
    border: none;
}

.user-dropdown.dropdown-toggle::after {
    display: none;
}

.received-amount {
    color: #948FB5;
}

.received-amount img {
    max-width: 20px;
    vertical-align: -5px;
    margin-right: 1px;
}

.add-investment-btn {
    color: #FF6624;
    border: none;
    background: transparent;
    font-size: 15px;
    font-weight: 700;
}

.pfr-table table {
    /* border: solid 1px #cfcfcf; */
    border-left: none;
    border-radius: 5px;
}

.pfr-table tr th {
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    background: #f6f5fd;
    padding: 10px 15px;
    border-left: solid 1px #cfcfcf;
    border-top: solid 1px #cfcfcf;
    border-radius: 5px;
}

.pfr-table tr td {
    font-weight: 700;
    font-size: 16px;
    color: #948FB5;
    border-radius: 5px;
    padding: 10px 15px;
    border-bottom: solid 1px #cfcfcf;
    border-left: solid 1px #cfcfcf;
}

.pfr-table tr th:last-child,
.pfr-table tr td:last-child {
    border-right: solid 1px #cfcfcf;
}



.swd-accordion.portfolio-card-list .accordion-button::after {
    display: block;
}

.swd-accordion .portfolio-card {
    border-left: none;
}

.vertical-tabs.custom-tabs .nav-tabs {
    width: 30%;
}

.vertical-tabs.custom-tabs .nav-tabs .nav-link {
    width: 100%;
    text-align: left;
}

.vertical-tabs.custom-tabs .nav-tabs li+li {
    margin-left: 0;
}

.vertical-tabs.custom-tabs .tab-content {
    width: 70%;
    min-height: 290px;
}

.vertical-tabs.custom-tabs .nav-tabs .nav-link.active {
    border: none;
    bottom: 0;
    background: #ebe9f5;
}

.close-pos-btn {
    position: absolute;
    right: 5px;
    top: 5px;
}

.deal-activity-flow {
    position: sticky;
    top: 172px;
}

button.ela-action {
    background: transparent;
    border: none;
    padding: 0px;
}

button.ela-action.selected {
    color: #FF6624;
}

.distribution-item {
    padding: 0 0 30px;
    position: relative;
    padding-left: 30px;
}

.distribution-circle {
    position: absolute;
    left: 2px;
    top: 3px;
    background: #0d942b;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.back-btn {
    background: transparent;
    border: none;
    vertical-align: -3px;
    margin-right: 5px;
    padding-left: 0;
}

.back-btn img {
    max-width: 26px;
    display: block;
}

.profile-circle {
    width: 100px;
    height: 100px;
    background: #EBE0FE;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.3em;
    color: #FF6624;
    text-align: center;
    overflow: hidden;
}

.profile-circle img {
    width: 100px;
    height: 100px;
}

.pf-edit-btn {
    background: #ff6624;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    right: 3px;
    bottom: 1px;
    overflow: hidden;
}

.pf-edit-btn img {
    max-width: 14px;
}

.pf-edit-btn .bi {
    color: #fff;
}

.dashfile-upload-btn {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.dashfile-upload-btn:focus {
    outline: none;
    border: none;
}

.profile-circle-header {
    width: 45px;
    height: 45px;
    background: #EBE0FE;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
}

.pre-screen-list {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    color: #948fb5;
    padding-left: 17px;
    margin-bottom: 25px;
}

.pre-screen-list li {
    padding: 10px 0;
    list-style-type: decimal;
}


.add-btn {
    color: #FF6624;
    border: none;
    background: transparent;
    font-size: 15px;
    font-weight: 700;
}

.remove-btn {
    border: none;
    background: transparent;
    font-size: 22px;
    color: #ff0000;
    padding: 9px;
}

.add-btn {
    border: none;
    background: transparent;
    font-size: 22px;
}

.startup-radio-list {
    margin-top: 15px;
}

.startup-radio-list .react-radio {
    width: calc(33.33% - 15px);
    margin-bottom: 10px;
}

.check-icon img {
    max-width: 80px;
}

.cs-card .card {
    /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
    border-color: #e9e7f6;
    border-radius: 13px;
}

.cs-card .card+.card {
    margin-top: 15px;
}

.cs-card .remove-btn {
    position: absolute;
    right: -9px;
    top: -9px;
    padding: 0;
    font-size: 18px;
}

.button-79 {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    background: #FF6624;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -.01em;
    line-height: 1.3;
    padding: 0.7rem 1.25rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    /* transform: translateZ(0) scale(1); */
    transition: transform .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-79:disabled {
    color: #787878;
    cursor: auto;
}

.button-79:not(:disabled):hover {
    transform: scale(1.05);
}

.button-79:not(:disabled):hover:active {
    transform: scale(1.05) translateY(.125rem);
}

.button-79:focus {
    outline: 0 solid transparent;
}

.button-79:focus:before {
    border-width: .125rem;
    content: "";
    left: calc(-1*.375rem);
    pointer-events: none;
    position: absolute;
    top: calc(-1*.375rem);
    transition: border-radius;
    user-select: none;
    -webkit-user-select: none;
}

.button-79:focus:not(:focus-visible) {
    outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
    transform: translateY(.125rem);
}

.custom-table table {
    caption-side: bottom;
    border-collapse: collapse;
}

.custom-table table thead tr th {
    background: #FF6624;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 13px 16px 13px 16px !important;
    text-align: left;
    line-height: 1.5rem;
    border: none;
}

.custom-table table tbody tr td {
    background: #fff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #616161;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
}

.custom-table table tbody tr:nth-of-type(odd) td {
    background-color: #f3f4fa;
}

.custom-table table tbody tr td:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.custom-table table tbody tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.custom-table table tbody tr:last-child td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.lp-summary-table table {
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.lp-summary-table table tr th:first-child,
.lp-summary-table table tr td:first-child,
.lp-summary-table table tr th:last-child,
.lp-summary-table table tr td:last-child {
    border-left: 1px solid rgba(224, 224, 224, 1) !important;
    border-radius: 0 !important;
}

.lp-summary-table table thead tr th {
    border-right: 1px solid rgb(120 120 120 / 30%) !important;
    border-bottom: 1px solid rgb(120 120 120 / 30%) !important;
    font-weight: 700;
    font-size: 14px;
}

.lp-summary-table table tbody tr td {
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    font-weight: 700;
    font-size: 14px;
    color: #616161;
}

.lp-summary-table table tbody tr.total-row td {
    border-right: 1px solid rgb(120 120 120 / 30%) !important;
}

.investor-onboarding-table .add-border {
    border-bottom: none !important;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.custom-menu-block {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
}

.custom-menu-block .side-bar {
    width: 100%;
    height: auto;
    position: sticky;
    top: 69px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    z-index: 1;
    background-color: #fff !important;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.custom-menu-block .side-bar ul {
    padding: 0;
    margin: 0;
}

.custom-menu-block .side-bar li {
    list-style: none;
    display: flex;
}

.custom-menu-block .side-bar li button,
.custom-menu-block .side-bar li a {
    text-decoration: none;
    display: block;
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
    max-width: 360px;
    min-width: 90px;
    min-height: 48px;
    padding: 12px 16px;
    color: #9e9e9e;
}

.custom-menu-block .side-bar li button.active:after,
.custom-menu-block .side-bar li a.active:after {
    content: '';
    border-bottom: solid 2px rgb(40, 53, 147);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}

.custom-menu-block .side-bar li button:hover,
.custom-menu-block .side-bar li a:hover {
    color: #FF6624;
}

.custom-menu-block .side-bar-tabs .nav-link.active {
    color: #FF6624;
}

.custom-menu-block .custom-card {
    box-shadow: none;
}

.custom-menu-block .main-content>.custom-card {
    box-shadow: none;
}

.view-investor-block .custom-card .card-header {
    background-color: #F3F4FA;
    color: rgb(40, 53, 147);
}

.deal-head-flex .circle-box img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.deal-head-flex .circle-box {
    width: 80px;
    height: 80px;
    min-width: 80px;
    overflow: hidden;
}

.text-decoration-none {
    text-decoration: none !important;
}

.cc-new-theme.custom-card .v-label {
    font-size: 14px;
    margin-bottom: 4px;
}

.cc-new-theme.custom-card .v-value {
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
}

.cc-new-theme.custom-card .v-value a {
    color: #9f5e30 !important;
}

.fullscreen .react-images__blanket {
    z-index: 1200;
}


.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}


@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
.css-13xc10z-MuiButtonBase-root-MuiTab-root {
    width: 700px !important;
    margin: 2px;
    background-color: #f77307 !important;
}
.css-1ujnqem-MuiTabs-root {
    margin: auto !important;
}

select {
    width: 205px !important;
    padding: 14px !important;
    border-radius: 6px !important;
}

.input input {
    width: 252px;
    padding: 16px !important;
    border-radius: 9px !important;
    margin: 1px;
}
.margin-bottom {
    margin-bottom: 1px;
}
.margin-personalize {
    text-align: center;
    border: 2px solid black;
    background-color: grey;
    color: #000;
    padding: 5px;
}
.boldText {
    font-weight: 700;
    display: inline-block;
    width: 15%;
}
li {
    list-style-type: none;
}
.options {
    text-align: center;
    display: inline-block;
    width: 70%;
}
.list-options {
    border: 1px solid gray;
    width: 95%;
    background-color: #fff;
}
.edit-button {
    display: inline-block;
    margin: 3px;
}
.active-edit {
    display: inline-block;
    margin: 1px;
}
.cs-card {
    position: relative;
    border-radius: 13px;
}
.text-center {
    text-align: center !important;
}
.value-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-transform: capitalize;
    color: #333333;
    margin-top: 20px;
}
.main-card {
    padding: 20px 40px 40px 25px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
}
.mt-3 {
    margin-top: 1rem !important;
}
.d-flex {
    display: flex;
}
.pl-2 {
    padding-left: 2rem !important;
}
.w-10 {
    max-width: 5% !important;
}
.cp-mxw {
    max-width: 174px;
    margin: 0 auto;
    padding-top: 1.4rem;
}
.info-box {
    position: relative;
    margin-left: 8px;
    display: inline-block;
}
.info-box .iconinfo {
    vertical-align: middle;
    cursor: pointer;
}
.info-pos {
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translate(0, -50%);
    background: #fff;
    border-radius: 4px;
    box-shadow: -3px 0 15px rgb(0 0 0 / 15%);
    padding: 15px;
    font-size: 10px;
    line-height: normal;
    text-align: center;
    width: 170px;
    display: none;
}
.info-box:hover .info-pos {
    display: block;
}
.info-pos:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: -10px;
    border-right: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.cpcs-cards {
    margin: 0 -2rem;
}
.cpcs-cards .col-md-4 {
    padding: 1.4rem 2rem;
}
.cp-mx-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 250px;
}
.cp-mx-list li {
    position: relative;
    width: 40%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
}
.cp-mx-list li span {
    font-weight: 800;
}
.cp-mx-list li:first-child {
    width: 60%;
}
.cp-mx-list li:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 6px;
    background: #ffd18c;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.cp-mx-list li.green:before {
    background: #96ca6d;
}
.custom-table table {
    caption-side: bottom;
    border-collapse: collapse;
}
.custom-table table thead tr th {
    background: #f77307;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 13px 16px 13px 16px !important;
    text-align: left;
    line-height: 1.5rem;
    border: none;
}
.custom-table table tbody tr td {
    background: #fff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15.3919px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #616161;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-align: left;
}
.custom-table table tbody tr:nth-of-type(odd) td {
    background-color: #f3f4fa;
}
.custom-table table tbody tr td:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
}
.custom-table table tbody tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.custom-table table tbody tr:last-child td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.text-success {
    color: #4caf50 !important;
}
.text-danger {
    color: #d32f2f !important;
}
.text-theme {
    color: #f77307 !important;
}
.rupees-sign {
    color: #948fb5 !important;
}
.main-logo img {
    height: 40px;
    max-width: 100%;
}
.custom-card {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}
.mnh-50 {
    min-height: 50%;
}
.mnh-100 {
    min-height: 100%;
}
.custom-card .custom-card {
    box-shadow: none;
}
.custom-card .v-label {
    font-size: 13px;
    margin-bottom: 3px;
}
.custom-card .v-value {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    word-break: break-word;
}
.custom-card .v-value a {
    word-break: break-word;
    font-weight: 600;
    color: #616161;
    text-decoration: none;
}
.custom-card .card-header {
    padding: 16px;
    background: transparent;
    border-bottom-color: #eeeeee;
    font-size: 0.875rem;
    font-weight: 500;
    color: #212121;
    line-height: 1.75;
    border-radius: 13px 13px 0 0;
}
.btn-success {
    background: #4caf50;
    border: 1px solid #c3bfbf;
    height: 42px;
    color: #fff;
}
.mnw-150 {
    min-width: 150px;
}
.custom-nav-tabs.nav-tabs .nav-item.show .nav-link {
    border: none;
    border-bottom: solid 2px transparent;
}
.back-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    display: block;
    font-size: 1.1em;
}
.edit-icon {
    position: absolute;
    right: 15px;
    top: 13px;
    color: rgba(148, 143, 181, 1);
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-colorPrimary.Mui-checked {
    color: #fff;
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-track {
    border-radius: 58px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-switch .MuiSwitch-sizeMedium .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background: #f77307;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 58px;
    opacity: 1;
}
.custom-switch .MuiSwitch-sizeMedium {
    padding: 4px 2px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: normal;
}
.css-oys24r-MuiFormControl-root > label[data-shrink='false'] {
    top: 0px;
}

.css-ujljcm-MuiGrid-root {
    justify-content: flex-start;
}
.theme-bg {
    background: #f77307 !important;
}
.steps-block {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
}
.lp-summary-table table tr td:first-child,
.lp-summary-table table tr th:last-child,
.lp-summary-table table tr td:last-child {
    border-left: 1px solid rgba(224, 224, 224, 1) !important;
    border-radius: 0 !important;
}
.cs-close svg {
    color: #cfcfcf !important;
}
.error-message {
    color: #721c24;
    padding: 10px;
}
.custom-menu-block .side-bar {
    width: 100%;
    height: auto;
    position: sticky;
    top: 69px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    z-index: 1;
    background-color: #fff !important;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.custom-menu-block .side-bar ul {
    padding: 0;
    margin: 0;
}

.custom-menu-block .side-bar li {
    list-style: none;
    display: flex;
}

.custom-menu-block .side-bar li button,
.custom-menu-block .side-bar li a {
    text-decoration: none;
    display: block;
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    padding: 12px 16px;
    color: #9e9e9e;
}

.custom-menu-block .side-bar li button.active:after,
.custom-menu-block .side-bar li a.active:after {
    content: '';
    border-bottom: solid 2px #f77307;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}
.custom-menu-block .side-bar li button:hover,
.custom-menu-block .side-bar li a:hover {
    color: #f77307;
}
.custom-menu-block .side-bar-tabs .nav-link.active {
    color: #f77307;
}
.custom-menu-block .custom-card {
    box-shadow: none;
}
.custom-menu-block .main-content > .custom-card {
    box-shadow: none;
}
.close-pos-right {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}
.mxh-scroll {
    max-height: calc(100vh - 459px);
    overflow-y: auto;
    padding: 0 15px 0 0;
}
.mxh-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.mxh-scroll::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}
.mxh-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.font-18 {
    font-size: 18px !important;
}
.h-50 {
    height: 50px !important;
}
.text-gray {
    color: #bbb9b9 !important;
}
.w-40 {
    width: 40% !important;
}
.gray-box {
    background: #f6f5fd;
    border: 1px solid #eee9fc;
    border-radius: 10px;
    padding: 15px;
    width: 200px;
}
.gray-box .icon {
    min-height: 45px;
    margin-bottom: 15px;
}
.gray-box .icon img {
    max-height: 45px;
    display: inline-block;
}
.icon-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #616161;
}
.or-cbox {
    width: 35px;
    height: 35px;
    min-width: 35px;
    line-height: 35px;
}

.deal-img-box .circle-box {
    width: 42px;
    height: 42px;
    min-width: 42px;
    line-height: 42px;
    background: #fff;
    border: solid 1px #cfcfcf;
    position: relative;
}
.deal-head-flex .circle-box img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.arrow-btn {
    background: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
}
.arrow-btn img {
    width: 23px;
    height: 23px;
}
.box-shadow-none {
    box-shadow: none !important;
}

.v-label.fw-600 {
    font-weight: 500 !important;
}
.checklist-master-card.custom-card .card-header {
    padding: 7px 10px 3px;
}
.checklist-master-card.custom-card .card-body {
    padding: 11px 10px 0;
}
.dhf-right {
    width: calc(100% - 80px);
    margin-left: 20px;
}
.bottom-border-height {
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 28px
} 

.dhf-right .gray-box {
    min-height: inherit;
    padding: 8px;
    width: 50px;
}
.dhf-right .icon {
    color: #616161 !important;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}
.dhf-right .icon a,
.dhf-right .icon button {
    color: #f77307 !important;
    padding: 0;
}
.dhf-right .icon img {
    max-width: 20px;
    max-height: 20px;
}
.dh-tabs {
    margin: 0;
}
.deal-head-flex .circle-box {
    width: 80px;
    height: 80px;
    min-width: 80px;
    overflow: hidden;
}
.document-list {
    padding: 0 0 0 20px;
}
.document-list li {
    padding: 15px 0;
}
.document-list li + li {
    border-top: 1px solid #eee9fc;
}

.demo-server-block {
    width: 100%;
    padding: 5px;
    background-color: #0000ff;
    text-align: center;
    font-size: 12px;
    color: #fff;
}
.main-header {
    flex-wrap: wrap;
}
.w-60 {
    width: 60%;
}
.dhf-company-name {
    color: #bbb9b9;
    font-weight: 600;
    font-size: 1.1rem;
}
.deal-status-value {
    line-height: 30px;
    text-align: center;
}
.amount-crore-text {
    color: #6e6e6e;
    font-size: 13px;
    margin-top: 6px;
}
.mxw-196 .MuiSelect-select {
    max-width: 100%;
}
.add-border {
    border: 1px solid rgba(224, 224, 224, 1);
}
.role-permission-table {
    overflow-x: auto;
}
.role-permission-table tbody tr td:first-child {
    font-weight: 600;
    color: #000;
}
.role-permission-table th label,
.role-permission-table td label {
    margin-right: 0;
    margin-left: 0;
}
.role-permission-table thead th h6 {
    color: #fff;
}
.role-permission-table thead th svg {
    fill: #fff !important;
}

.info-box.info-left .info-pos {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
    left: inherit;
    right: 35px;
    width: 520px;
    text-align: left;
    font-size: 12px;
    background: #fff;
    z-index: 10000;
    top: -8px;
    transform: inherit;
    font-weight: 400;
}
.info-box.info-left .info-pos strong {
    font-weight: 900;
}
.info-box.info-left .info-pos > div {
    padding: 4px 0;
}
.info-box.info-left .info-pos:before {
    display: none;
}
.info-box.info-left .info-pos:after {
    content: '';
    position: absolute;
    right: -10px;
    top: 15px;
    border-left: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.info-box.info-right .info-pos {
    box-shadow: 0px 0 15px rgba(0, 0, 0, 0.15);
    left: 50px;
    width: 520px;
    text-align: left;
    font-size: 12px;
    background: #fff;
    z-index: 10000;
    top: -8px;
    transform: inherit;
    font-weight: 400;
}
.info-box.info-right .info-pos strong {
    font-weight: 900;
}
.info-box.info-right .info-pos > div {
    padding: 4px 0;
}
.info-box.info-right .info-pos:before {
    display: none;
}
.info-box.info-right .info-pos:after {
    content: '';
    position: absolute;
    left: -10px;
    top: 15px;
    border-right: solid 10px #fff;
    border-top: solid 10px transparent;
    border-bottom: solid 10px transparent;
}
.form-control {
    padding: 10px 14px;
    border-radius: 4px;
    background: #fafafa;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4375em;
    font-family: 'Inter', sans-serif;
    color: #616161;
    box-sizing: border-box;
    border-color: #a0a8da;
    border-width: 0.5px;
}
.w-70 {
    width: 80px;
    min-width: 80px;
}

.vp-investment-dtl-table {
    overflow-x: auto;
}
.custom-hr {
    border-top: solid 1px #e0e0e0;
    padding-top: 12px;
    margin-top: 12px;
}
.main-checkbox h6 {
    font-size: 16px;
    font-weight: 700;
}
.ps-30 {
    padding-left: 30px;
}

.ud-box {
    padding: 10px 10px;
}
.ud-header-row .col {
    padding: 10px 10px;
}
.list-view .upload-document-row .col {
    flex: auto;
    padding: 10px 10px;
}
.list-view .upload-document-row .col .ud-box {
    padding: 0;
}
.upload-document-row .col:first-child {
    width: 25%;
    text-align: left;
}
.upload-document-row .col:nth-child(2) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:nth-child(3) {
    width: 20%;
    text-align: left;
}
.upload-document-row .col:nth-child(4) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:nth-child(5) {
    width: 15%;
    text-align: left;
}
.upload-document-row .col:last-child {
    width: 10%;
    text-align: center;
}
.upload-document-list .upload-document-row:nth-of-type(even) {
    background-color: #f3f4fa;
}
.ud-box .xs-title {
    text-decoration: none;
    color: rgb(97, 97, 97);
    font-weight: 400;
    font-size: inherit;
}
.ud-box .xs-title .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 50px);
}
.ud-box .xs-title .title-line-break {
    max-height: 38px;
    overflow: hidden;
}
.upload-document-row {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    align-items: center;
}
.remove-border-collapse {
    border-collapse: inherit !important;
    border-top: 1px solid rgb(224, 224, 224) !important;
}

.view-type {
    display: flex;
    align-items: center;
}
.transparent-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #616161;
}
.upload-document-list.list-view {
    border: 1px solid rgb(224, 224, 224);
    overflow-x: auto;
    max-height: calc(100vh - 350px);
}
.upload-document-list.grid-view {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.upload-document-list.grid-view .upload-document-row {
    background-color: #f3f4fa;
    color: #f77307;
    width: calc(20% - 20px);
    margin: 0 10px;
    padding: 5px;
    border-radius: 8px;
    border-top: none;
    margin-bottom: 20px;
}
.text-black {
    color: #333 !important;
}
.ud-header-row {
    color: white;
    background-color: #f77307;
    position: sticky;
    top: 0;
}

.skeleton-table .tg {
    border-collapse: collapse;
    border-spacing: 0;
}

.skeleton-table.custom-table table thead tr th {
    font-size: 14px;
    padding: 13px 16px 13px 16px !important;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
}

.skeleton-table.custom-table table tbody tr td {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 16px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
}

.line {
    width: 100%;
    height: 25px;
    margin-top: 0;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229, 229, 229, 0.8) 40px, #f4f4f4 80px);
    background-size: 600px;
    animation: shine-lines 2s infinite ease-out;
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 140px;
    }
}

.file-upload-menu {
    position: relative;
}
.file-upload-menu input {
    position: absolute;
    left: -90px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}
.mxw450 .cs-modal {
    max-width: 450px;
}
.ud-link {
    color: rgb(97, 97, 97);
    text-decoration: none;
}

.upload-document-block .cs-border {
    border-bottom: solid 1px rgb(224, 224, 224);
    margin: 15px 0;
}
.ud-icon {
    max-width: 30px;
}
.cd-breadcrumb {
    font-size: 15px !important;
}
.cd-breadcrumb button.ud-link {
    background: transparent;
    border: none;
    padding: 0;
}
.cd-breadcrumb a:first-child svg,
.cd-breadcrumb button:first-child svg {
    display: none;
}
.cd-breadcrumb svg {
    vertical-align: -7px;
}
.cd-breadcrumb .dot-link {
    padding-left: 15px;
    padding-right: 5px;
}
.mxw550 .cs-modal {
    max-width: 550px;
}

.file-upload-box {
    border: 2px dashed rgba(0, 0, 0, 0.23);
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}
.file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.file-link {
    cursor: pointer;
    color: #7b6f47;
    text-decoration: underline;
    margin-left: 3px;
}
.file-upload-box .file-link:hover {
    text-decoration: none;
}
.file-atc-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}
.file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}
.file-detail {
    flex: 1;
    width: calc(100% - 210px);
}
.file-detail h6 {
    word-break: break-all;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 0;
    vertical-align: top;
}
.file-detail p {
    font-size: 12px;
    color: #b3b3b3;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 8px;
    margin-top: 0;
    display: block;
    height: auto;
}
.file-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.file-action-btn {
    font-size: 12px;
    color: #937528;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}
.file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline;
}
.file-atc-box:last-child {
    margin-bottom: 0;
}
.upload-doc-title svg {
    vertical-align: -6px;
}
.font-16 {
    font-size: 16px !important;
}
.no-search-results {
    padding: 40px 15px 20px;
    text-align: center;
}
.upload-btn svg {
    vertical-align: middle;
}
.grid-view .no-record-col {
    padding: 0 10px;
}
.grid-view .no-record-box {
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.inv-left {
    max-width: calc(100% - 300px);
    flex-basis: calc(100% - 300px);
}
.inv-right {
    max-width: 300px;
    flex-basis: 300px;
}
.custom-box {
    background-color: #ffffff;
    color: #616161;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background-image: none;
    border-radius: 4px;
    overflow: hidden;
    border: none;
    border-color: #90caf975;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px 20px;
    position: relative;
}
.ud-box2 {
    /* Add other styling properties as needed */
    cursor: pointer;
}
.investor-onboarding-table .add-border {
    border-bottom: none !important;
}
.selected-item {
    background-color: #b5a58f !important;
}
.blue-text {
    color: #0000ff !important;
}
.error {
    margin-top: 0;
    color: #ef5350;
    font-size: 0.77rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 1.66;
    margin-left: 33px;
}
.cursor-pointer {
    cursor: pointer !important;
}
.action-btn {
    background-color: #fff;
    border: 1px solid #f77307;
    height: 42px;
    color: #f77307;
}

.deal-table tr:nth-of-type(odd) td {
    background-color: #f3f4fa;
}
.deal-table tr:nth-of-type(even) td {
    background-color: #fff;
}
.stickyTableHead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
}

.document-list-table table tr:first-child td {
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.document-list-table table tr td:last-child {
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.document-list-table table tr td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-left: 1px solid rgba(224, 224, 224, 1);
    padding: 8px 12px;
}
.pre-wrap {
    white-space: pre-wrap;
}

div.MuiTableContainer-root::-webkit-scrollbar ,div.custom-table::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
    display: none;
}

div.MuiTableContainer-root::-webkit-scrollbar-thumb div.custom-table::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 25px;
}

div.MuiTableContainer-root::-webkit-scrollbar-thumb:hover div.custom-table::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.value-link {
    color: #616161;
}

.mxh-scroll-checklist {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 15px;
}
.mxh-scroll-checklist::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}
.mxh-scroll-checklist::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}
.mxh-scroll-checklist::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}